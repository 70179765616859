import React, { useState } from "react"
import AccordionSection from "../components/accordionSection"

const Accordion = props => {
  const { allowMultipleOpen, children } = props
  const initialSections = {}
  children.forEach(child => {
    if (child.props.isOpen) {
      initialSections[child.props.label] = true
    }
  })
  const [openSections, setOpenSections] = useState(initialSections)
  const onClick = label => {
    const isOpen = !!openSections[label]
    if (allowMultipleOpen) {
      setOpenSections({ ...openSections, [label]: !isOpen })
    } else {
      setOpenSections({ [label]: !isOpen })
    }
  }
  return (
    <div {...props}>
      {children.map((child, index) => {
        return (
          <AccordionSection
            key={index}
            isOpen={!!openSections[child.props.label]}
            label={child.props.label}
            content={child.props.content}
            onClick={onClick}
          >
            {child.props.children}
          </AccordionSection>
        )
      })}
    </div>
  )
}

export default Accordion
