import React from "react"

import add from "../images/add.svg"
import remove from "../images/remove.svg"

const AccordionSection = ({children, label, content, isOpen, onClick}) => {
  const onClickHandler = (event) => {
    if(!event.target.href) {
      onClick(label)
    }
  }
  return (
    <div className="accordion-container">
      <div className="accordion-wrapper" onClick={onClickHandler} style={{ paddingBottom: isOpen? `1rem` : `0` }}>
        <div className="city">{content.city === 'Brussels HQ' ? (
          <>Brussels <span style={{
            fontSize: `1.17rem`
          }}>HQ</span></>
        ) : (
          <>{content.city}</>
         )}</div>
        <div className="address"><a target="_blank" rel="noopener noreferrer" alt="Google Maps" href={content.googleMaps} style={{paddingRight: `2rem`}}>{content.address}<br/>{content.address2}</a></div>
        <div className="contact">
          <div style={{ paddingRight: `2rem`}}><a href={'mailto:' + content.email}>{content.email}</a><br/><a href={`tel:${content.phone}`}>{content.phone}</a></div>
          <img style={{ marginBottom: `0` }} src={isOpen ? remove : add} alt=""/>
        </div>
      </div>
      {isOpen && (
        <div>
          {children}
        </div>
      )}
    </div>
  )
}

export default AccordionSection
