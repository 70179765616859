import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import styled from "styled-components"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Accordion from "../components/accordion"
import Footer from "../components/footer"
import "./about.sass"

const OfficeRow = styled.div`
  display: grid;
  grid-template-column: repeat(4, 1fr);
  color: black;

  a {
    color: #121212;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const H2 = styled.h2`
  font-size: 1.5rem;
  color: #fff;
  width: 50%;
  margin-bottom: 20px;
  font-weight: 700;
`

const AboutPage = ({ data }) => {
  let offices = data.allContentfulOffice.nodes
  let regionSlug = data.contentfulRegion.slug
  // Offices are sorted by the order attribute by default.
  // But the office matching the current region must be placed first.
  offices.sort(function(x,y){ return x.region?.slug === regionSlug ? -1 : y.region?.slug === regionSlug ? 1 : 0; })
  const image = getImage(data.allContentfulAsset.nodes[0].localFile)
  const bgImage = convertToBgImage(image)
  return (
    <Layout region={data.contentfulRegion.slug} fluid={true}>
      <Helmet htmlAttributes={{ class: "about" }} />
      <SEO title="About" />
      <BackgroundImage
        Tag="div"
        {...bgImage}
        style={{
          backgroundSize: `100%`,
          backgroundPosition: `left top`,
          backgroundColor: `#0E0E0E`,
          backgroundAttachment: `fixed`
        }}
      >
        <div className="wrapper">
          <div className="big-title">
            Raw emotions, <br />
            beautifully crafted.
          </div>
          <div className="colums">
            <H2>
              About us
            </H2>
            <div className="text">
              <div>
                Yes, Hamlet is a play by Shakespeare. And a cigar brand. But a
                Hamlet is a small village. Well, not even a village, really,
                more like a <strong>small group of houses</strong>.
                <br />
                &nbsp;
                <br />
                This particular Hamlet is populated with creative craftspeople,
                forging strong images and sounds, powerful content for new and
                old media, experimenting with form, structure and cutting edge
                technology.
                <br/>
                &nbsp;
                <br />
                With Hamlet, we've created a place where <strong>talent finds a home</strong>;
                directors, producers, actors and writers with memorable stories
                to tell. They will join in, stay for a while and then maybe move
                on, but never without leaving their mark.
                <br />
                &nbsp;
                <br />
                Our Hamlet is open to anyone, visitors, guests, customers, or simply, the curious.
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <Accordion style={{ marginTop: `1rem`, marginBottom: `5.25rem` }}>
        {offices.map((office, index) => {
          return (
            <div
              key={office.id}
              label={office.city}
              content={office}
              isOpen={index === 0}
            >
              <OfficeRow>
                {office.team?.map(employee => {
                  return (
                    <div className="people" key={employee.id}>
                      <div className="name">
                        {employee.name}
                        <br />
                        <span>{employee.position}</span>
                      </div>
                      <div>
                        <a href={`mailto:${employee.email}`}>
                          {employee.email}
                        </a>
                        <br />
                        <a href={`tel:${employee.phone}`}>{employee.phone}</a>
                      </div>
                    </div>
                  )
                })}
              </OfficeRow>
            </div>
          )
        })}
      </Accordion>
      <Footer regionSlug={regionSlug} />
    </Layout>
  )
}

export const query = graphql`
  query AboutPage($regionId: String!) {
    contentfulRegion(id: { eq: $regionId }) {
      slug
    }
    allContentfulOffice(sort: { fields: order }) {
      nodes {
        id
        region {
          slug
        }
        city
        address
        address2
        email
        googleMaps
        phone
        team {
          id
          email
          name
          phone
          position
        }
      }
    }
    allContentfulAsset(filter: { title: { eq: "About Background" } }) {
      nodes {
        id
        title
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1280
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`

export default AboutPage
